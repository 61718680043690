"use client";

import { fetchPostJSON } from "@knowt/syncing/fetchFunctions/fetchWrappers";
import { callVerifyStripeCheckout } from "@knowt/syncing/hooks/user/graphqlUtils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useRouter } from "@bprogress/next";
import { useState } from "react";
import toast from "react-hot-toast";
import { mutate } from "swr";
import getStripe from "@/utils/stripe/stripeJS";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import { StripeStatus } from "@knowt/syncing/hooks/user/subscriptionConstants";
import noop from "@knowt/syncing/utils/noop";
import { verifyStripeCheckoutSession } from "../utils/stripeServerUtils";
import { useUpgradePopupContextSelector } from "@/features/Payments/PaywallPopup/UpgradePopupContext";
import { DPAType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import { StableSWRKeys } from "@knowt/syncing/hooks/swr/swr";

const usePricingCardsUtils = ({
    priceId,
    onClick = noop,
    isCurrentPlan,
    promoCode: _promoCode,
}: {
    priceId?: string;
    onClick?: () => void;
    isCurrentPlan?: boolean;
    promoCode?: string | null | undefined;
}) => {
    const router = useRouter();
    const { user, organization, isUnder13, activeSubscription, isSubscriptionActive } = useCurrentUser();
    const { openConfirmDialog } = useConfirmDialog();
    const openUpgradePopup = useUpgradePopupContextSelector(state => state.openUpgradePopup);

    const [isLoading, setIsLoading] = useState(false);

    // TODO: add back promo code for upgrade subscriptions
    const onChangePlans = async () => {
        // get `promoCode` from search params
        const promoCode = _promoCode ?? new URLSearchParams(window.location.search).get("promoCode");

        toast.loading("Changing your plan...", { id: "CHANGE_PLANS" });
        fetchPostJSON("/api/subscription/update", {
            subscriptionId: activeSubscription.subscriptionId,
            priceId,
            promoCode,
        })
            .then(async res => {
                if (res.statusCode !== 200) {
                    throw new Error(res.statusText);
                }
                await callVerifyStripeCheckout(user?.customerId);
                await mutate(StableSWRKeys.USER);
                toast.success("Successfully changed plans!", { id: "CHANGE_PLANS" });
            })
            .catch(err => {
                toast.error(`Failed to change plans: ${err.message}`, { id: "CHANGE_PLANS" });
            });
    };

    const handleSubscribeEvent = async () => {
        const promoCode = _promoCode ?? new URLSearchParams(window.location.search).get("promoCode");

        if (!user) {
            return;
        }

        if (isUnder13 || organization?.dpa?.type === DPAType.USE) {
            return openUpgradePopup({
                // event doesn't matter here
                event: UpgradeEvent.REMOVE_ADS,
                context: {},
            });
        }

        onClick();
        // Basic Plan
        if (!priceId) {
            if (isSubscriptionActive) {
                return toast.error("To unsubscribe, go to your account settings.");
            } else {
                return router.push("/home");
            }
        } else if (isCurrentPlan) {
            return router.push("/plans/status");
        }

        const isTrialing = activeSubscription?.status === StripeStatus.TRIALING;

        // Switch Plan
        if (activeSubscription) {
            openConfirmDialog({
                title: `Are you sure you want to ${isTrialing ? "switch trials" : "change plans"}?`,
                subtitle: isTrialing
                    ? "Your free trial will switch for the remainder of the period, and you will be charged for that new plan at the end of the trial"
                    : "You will be charged the pro-rated amount for the remainder of your current billing cycle.",
                style: { borderRadius: "4rem" },
                rounded: true,
                cardSize: "60rem",
                spacing: 2.2,
                subtitleFontSize: "1.9rem",
                subtitleFontWeight: "500",
                buttonColor: "black",
                buttonTextColor: "white",
                simpleCancelBtn: true,
                onConfirm: async () => onChangePlans(),
            });
            return;
        }

        setIsLoading(true);

        try {
            // Create a Checkout Session.
            const response = await fetchPostJSON("/api/checkout_sessions", {
                priceId,
                email: user?.Email,
                // code param
                code: promoCode,
            });

            if (response.statusCode === 500) {
                return;
            }

            if (response.statusCode === 403) {
                toast.loading("Retreiving your subscription...", { id: "VERIFY_SUBSCRIPTION" });
                // User is already subscribed

                await verifyStripeCheckoutSession({
                    user,
                    sessionId: response.data.id,
                });

                toast.success("Subscription verified", { id: "VERIFY_SUBSCRIPTION" });
                return router.push("/plans/status");
            }

            const stripe = await getStripe();

            const { error: _error } = await stripe.redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: response.data.id,
            });

            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `error.message`.
        } finally {
            setIsLoading(false);
        }
    };

    const reactivateSubscription = async () => {
        // Basic Plan
        if (!priceId) {
            router.push("/home");
        } else {
            try {
                setIsLoading(true);

                const response = await fetchPostJSON("/api/subscription/update", {
                    subscriptionId: activeSubscription.subscriptionId,
                    priceId,
                });

                if (response.statusCode === 500) {
                    return;
                }
            } finally {
                await callVerifyStripeCheckout(activeSubscription.customerId);
                toast.success("Subscription Reactivated");
                setIsLoading(false);
                router.push("/plans/status");
            }
        }
    };

    return {
        handleSubscribeEvent,
        isLoading,
        onChangePlans,
        reactivateSubscription,
    };
};

export default usePricingCardsUtils;
