"use client";

import { Banner, BannerType, SubscriptionType } from "@knowt/syncing/graphql/schema";
import useLocalStorage from "react-use/lib/useLocalStorage";
import CloseIcon from "@mui/icons-material/Close";
import useListBanners from "@knowt/syncing/hooks/banners/useListBanners";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { StripeStatus, STUDENT_PRICE_ID, TEACHER_PRICE_ID } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { getParsedUTCDate, now, TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { themeColors } from "@/utils/themeColors";
import { useRouter } from "@bprogress/next";
import { spacing } from "@/utils/spacing";
import { progressingEvent, StreakStatus } from "@knowt/syncing/hooks/gamification/monitoring/progressing";
import { getDaysUntil } from "@knowt/syncing/hooks/gamification/monitoring/streakMonitoring/utils";
import CustomBanner from "./CustomBanner";
import StreakPauseIcon from "@/components/icons/StreakPauseIcon";
import usePricingCardsUtils from "@/features/Payments/hooks/usePricingCardsUtils";

const BannerItem = ({ banner }: { banner: Banner }) => {
    const { user, isTeacher, subscriptionType } = useCurrentUser();
    const { checkAuth } = useCheckAuth();
    const router = useRouter();

    const [bannerDisplaying, setBannerDisplaying] = useLocalStorage(`banner-${banner.bannerId}`, true, {
        raw: false,
        serializer: (value: boolean) => value.toString(),
        deserializer: (value: string) => value === "true",
    });

    const { handleSubscribeEvent } = usePricingCardsUtils({
        // TODO: Change to correct priceId (limitless monthly 10 is deprecated)
        priceId: isTeacher ? TEACHER_PRICE_ID.LIMITLESS_MONTHLY_10 : STUDENT_PRICE_ID.LIMITLESS_MONTHLY_10,
        isCurrentPlan: false,
        promoCode: banner.promoCode,
    });

    const getBannerColors = (background = false) => {
        if (background) {
            switch (banner.type) {
                case BannerType.INFO:
                    return "#78CFF5";
                case BannerType.URGENT:
                    return "#F8948D";
                case BannerType.WARNING:
                    return "#FEDA6F";
                case BannerType.PROMO:
                    return "#15504a";
            }
        } else {
            switch (banner.type) {
                case BannerType.INFO:
                    return "#025597";
                case BannerType.URGENT:
                    return "#B21B1B";
                case BannerType.WARNING:
                    return "#C38902";
                case BannerType.PROMO:
                    return themeColors.pureWhite;
            }
        }
    };

    if (!bannerDisplaying) {
        return null;
    }

    if (banner.type === BannerType.PROMO) {
        return (
            subscriptionType === SubscriptionType.BASIC &&
            ((user?.ai?.videos || 0) > 0 || (user?.ai?.pdfs || 0) > 0) && (
                <FlexRowAlignCenter
                    style={{
                        backgroundColor: getBannerColors(true),
                        color: getBannerColors(),
                        justifyContent: "center",
                        gap: spacing.XS,
                        padding: spacing.SM,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        checkAuth({
                            event: AuthEvent.PROMO_BANNER,
                            onAuthSuccess: () => {
                                Mixpanel.track("Promo Banner Clicked", {
                                    promoCode: banner.promoCode,
                                });
                                handleSubscribeEvent();
                            },
                        });
                    }}>
                    <span style={{ fontSize: "1.8rem", lineHeight: "2.8rem", margin: "auto" }}>{banner.message}</span>
                </FlexRowAlignCenter>
            )
        );
    }

    return (
        <FlexRowAlignCenter
            style={{
                backgroundColor: getBannerColors(true),
                color: getBannerColors(),
                cursor: banner.href ? "pointer" : "default",
                padding: spacing.SM,
                justifyContent: "center",
                gap: spacing.XS,
                position: "relative",
            }}
            onClick={() => {
                if (banner.href) return router.push(banner.href);
            }}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <span style={{ fontSize: "1.8rem", lineHeight: "2.8rem", margin: "auto" }}>{banner.message}</span>

                <FlexRowAlignCenter style={{ gap: "2rem", position: "absolute", right: "3rem" }}>
                    <CloseIcon
                        onClick={() => {
                            setBannerDisplaying(false);
                        }}
                        style={{
                            cursor: "pointer",
                            strokeWidth: 2,
                            width: "2rem",
                            height: "2rem",
                        }}
                        color={"inherit"}
                    />
                </FlexRowAlignCenter>
            </div>
        </FlexRowAlignCenter>
    );
};

const NavbarBanner = () => {
    const { user, activeSubscription } = useCurrentUser();
    const bannerList = useListBanners();

    const isSubscriptionPaused = activeSubscription?.status === StripeStatus.PAUSED;

    const openLongPausePopup = () => {
        if (!user) return;

        const numOfDaysRemaining = getDaysUntil(user.longPauseEnd, user.timeZone || undefined);
        progressingEvent.notify({ [StreakStatus.RESUME_LONG_PAUSE]: { daysRemaining: numOfDaysRemaining } });
    };

    if (!user) return null;

    return (
        <FlexColumn style={{ height: "fit-content" }}>
            {bannerList?.map(bannerObj => (
                <BannerItem banner={bannerObj} key={bannerObj?.bannerId} />
            ))}
            {isSubscriptionPaused && (
                <BannerItem
                    banner={{
                        __typename: "Banner",
                        bannerId: "pause-subscription",
                        type: BannerType.WARNING,
                        message: `Your subscription is paused until ${getParsedUTCDate(
                            new Date((activeSubscription?.resumes_at || 0) * 1000)
                        )}. Click here to unpause and continue using Kai!`,
                        startTime: 0,
                        endTime: activeSubscription?.resumes_at || now(),
                        href: "/settings#plans",
                    }}
                    key={"pause"}
                />
            )}
            {user?.deleteReq && (
                <BannerItem
                    banner={{
                        __typename: "Banner",
                        bannerId: "delete",
                        type: BannerType.URGENT,
                        message:
                            "You have requested your account to be deleted within 30 days. Please visit settings to restore your account.",
                        startTime: 0,
                        endTime: now() + TIME_SECONDS.MONTH,
                    }}
                    key={"delete"}
                />
            )}

            {user?.gameBlock && (
                <BannerItem
                    banner={{
                        __typename: "Banner",
                        bannerId: "game-block",
                        type: BannerType.URGENT,
                        message:
                            "Your account has been blocked from gamification features due to suspicious activity. If this is a mistake, please contact support.",
                        startTime: 0,
                        endTime: now() + TIME_SECONDS.MONTH,
                    }}
                    key={"delete"}
                />
            )}

            <CustomBanner
                id="long-pause-banner"
                isVisible={!!user.longPauseEnd}
                disableClose
                onClick={openLongPausePopup}
                style={{
                    backgroundColor: themeColors.neutral2,
                    color: themeColors.neutralBlack,
                }}>
                <StreakPauseIcon showResumeIcon />
                <span className="bodyBold1">
                    Long Pause is active!{" "}
                    <span className="body1">
                        You won't earn any rewards while it's enabled. Click here to deactivate it.
                    </span>
                </span>
            </CustomBanner>
        </FlexColumn>
    );
};

export default NavbarBanner;
