import { client } from "@/utils/client/graphql";
import { listBanners } from "@/graphql/queries";
import { Banner } from "@knowt/syncing/graphql/schema";
import { platform } from "@/platform";

export const BANNERS_LAST_UPDATED_KEY = "banners-last-updated";

export const fetchListBanners = async (): Promise<Banner[]> => {
    return await client
        .query({
            query: listBanners,
            variables: { input: {} },
        })
        .then(({ data }) => {
            return data?.listBanners.items;
        })
        .catch(async e => {
            const { report } = await platform.analytics.logging();

            report(e, "fetchListBanners");
            return [];
        });
};
