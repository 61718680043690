import { Banner } from "@knowt/syncing/graphql/schema";
import { BANNERS_LAST_UPDATED_KEY, fetchListBanners } from "./utils";
import { platform } from "@/platform";
import { TIME } from "@/utils/dateTimeUtils";
import { useSWRImmutable } from "@/hooks/swr/immutable";

const useListBanners = (): Banner[] =>
    useSWRImmutable("listBanners", async () => {
        const storage = await platform.storage();
        const banners = await storage.getWithExpiry(BANNERS_LAST_UPDATED_KEY);
        if (banners === "[]") {
            return [];
        }

        if (!banners) {
            let serverBanners = await fetchListBanners();
            if (!serverBanners) {
                serverBanners = [];
            }
            await storage.setWithExpiry(BANNERS_LAST_UPDATED_KEY, JSON.stringify(serverBanners), TIME.HOUR);
            return serverBanners;
        }

        return JSON.parse(banners) as Banner[];
    }).data;

export default useListBanners;
