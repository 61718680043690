"use client";

import { FlexRowAlignCenter } from "@/components/Flex";
import { dynamicKeyValueStore } from "@/utils/dynamicKeyValueStore";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { X } from "lucide-react";
import { ElementType, useState } from "react";

type CustomBannerProps<T extends ElementType> = {
    as?: T;
    children?: React.ReactNode;
    className?: string;
    id: string;
    isVisible: boolean;
    disableClose?: boolean;
    disablePermanentClose?: boolean;
} & React.ComponentPropsWithoutRef<T>;

const CustomBannerComponent = <T extends ElementType = "div">({
    as,
    children,
    id,
    isVisible,
    disableClose,
    disablePermanentClose,
    ref,
    ...rest
}: CustomBannerProps<T>) => {
    const Element = as || FlexRowAlignCenter;

    const [forceHideBanner, setForceHideBanner] = useState(
        !disablePermanentClose && !disableClose && (!!dynamicKeyValueStore.get(id) || false)
    );

    const handleClose = (e: React.MouseEvent) => {
        if (!disablePermanentClose) dynamicKeyValueStore.set(id, "true");
        setForceHideBanner(true);
        e.stopPropagation();
    };

    if (forceHideBanner || !isVisible) return null;

    return (
        <Element
            ref={ref}
            {...rest}
            style={{
                cursor: "pointer",
                backgroundColor: themeColors.neutralBlack,
                color: themeColors.neutralWhite,
                width: "100%",
                padding: `${spacing.SM} ${spacing.LG_2}`,
                justifyContent: "center",
                gap: spacing.MD,
                position: "relative",
                ...rest.style,
            }}>
            {children}
            {!disableClose && (
                <X
                    onClick={handleClose}
                    size={24}
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: "3rem",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                    }}
                />
            )}
        </Element>
    );
};

export default CustomBannerComponent;
